import React from "react";

const Blog = () => {
  return (
    <div style={{ margin: "10rem 0" }}>
      <h1>Blog coming soon</h1>
    </div>
  );
};

export default Blog;
