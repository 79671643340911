import { GitHub, LinkedIn } from "@mui/icons-material";
import { Button, Container } from "@mui/material";
import React from "react";
import Typical from "react-typical";
import "../styles/hero.style.scss";

const Hero = () => {
  return (
    <Container className="hero_container">
      <h3>Greetings, This is</h3>
      <h1>Abdallah Daiyan 👋</h1>
      {/* @TODO: Arekta typical package use kora lagbe, eitay kaj hobena */}
      <Typical
        steps={[
          "Problem Solver",
          2000,
          "Backend Focused",
          2000,
          "Linux Enthusiasist",
          2000,
          "Code Freak",
          2000,
          "Nerd",
          2000,
        ]}
        loop={Infinity}
        wrapper="h2"
      />
      <div className="hero_icon_group">
        <a
          href="https://www.github.com/coderDaiyan"
          target="_blank"
          rel="noreferrer"
        >
          <GitHub />
        </a>
        <a
          href="https://www.linkedin.com/in/abdallah-daiyan/"
          target="_blank"
          rel="noreferrer"
        >
          <LinkedIn />
        </a>
      </div>
      <div className="hero_button_group">
        <a
          href="https://drive.google.com/file/d/1-kyWBR8OFiGGJTQWjplDo61hwoGSu3K-/preview"
          target="_blank"
          rel="noreferrer"
        >
          <Button variant="contained" color="success" size="large">
            View Resume
          </Button>
        </a>
        <a
          href="https://www.linkedin.com/in/abdallah-daiyan/"
          target="_blank"
          rel="noreferrer"
        >
          <Button variant="outlined" color="success" size="large">
            Hire me
          </Button>
        </a>
      </div>
    </Container>
  );
};

export default Hero;
