import { Grid } from "@mui/material";
import React from "react";
import Vector from "../assets/vector.png";
import "../styles/whoami.style.scss";

const WhoAmI = () => {
  return (
    <Grid container className="whoami_container">
      <Grid item md={5}>
        <img src={Vector} alt="" />
      </Grid>
      <Grid item md={7}>
        <h1>Who AM I</h1>
        <p>
          Daiyan is a frontend focused backend developer. He loves to face any
          problem and solve it.He's a curious minded programmer, linux lover and
          coding freak. He also worked in many voluntary positions. He's
          favorite technology is node. Also he did some competitive programming.
          <br /> <br />
          <em>
            <strong>Also, he uses arch btw</strong>
          </em>
        </p>
      </Grid>
    </Grid>
  );
};

export default WhoAmI;
